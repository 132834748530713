import React from 'react'

const LoadingSpinner = ({ title }) => (
  <div className="loading-spinner__container">
    <div className="loading-spinner">
      {title ? <h5>{title}</h5> : ''}
      <div className="loading-spinner__dots">
        <div className="loading-spinner__dot"></div>
        <div className="loading-spinner__dot"></div>
        <div className="loading-spinner__dot"></div>
      </div>
    </div>
  </div>
)

export default LoadingSpinner
